<template>
    <div
            v-if="this.page_settings"
            class="wrapper_poly">

        <loading v-if="!this.$store.getters.loading" />

        <headerBg data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up" :white="true"
            :title="data.page_settings.methylamine_title" :description="data.page_settings.methylamine_description"
            :btn="false" :down="true" :bg="'https://api.grunchem.eu/storage/' + data.page_settings.methylamine_image"
            :amimation="true" />

        <div class="poly">

            <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in" :safety="true"
                :specification="true" :safetyLink="data.methylamine.media.pdf_1[0].default"
                :safetyTitle="data.header_footer.vocabulary.safety_btn" :specificationTitle="data.header_footer.vocabulary.specification_btn"
                :specificationLink="data.methylamine.media.pdf_2[0].default"
                               :archiveTitle="data.header_footer.vocabulary.archive_btn"
                               :btnArchive="data.methylamine.media.archive[0].default"
                               :img= "data.page.media.image[0].default"
                :subtitle="data.page.title" :title="data.page.sub_title" :description="data.page.description" />

            <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in" :reverse="true"
                :img="'https://api.grunchem.eu/storage/' + data.page_settings.application_image"
                :subtitle="data.page_settings.application_title"
                :description="data.page_settings.application_description" />

            <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                :img="'https://api.grunchem.eu/storage/' + data.page_settings.patent_image"
                :title="data.page_settings.patent_title" :description="data.page_settings.patent_description"
                :btnPatent="data.header_footer.vocabulary.patent_link" :btnTitle="data.header_footer.vocabulary.views_patent" />

            <product-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                :title="data.page_settings.products_title" :description="data.page_settings.products_description">

                <product-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                    v-for="products in data.products" :key="products.title" :img="products.media.icon[0].default"
                    :title="products.title" :description="products.short_description"
                    :btn-link="'/' + locale + '/products/' + products.slug" :btn-title="data.header_footer.vocabulary.btn_read_more" />

            </product-wrapper>
        </div>
    </div>
</template>

<script>
    import loading from '../components/loading'
    import {
        RepositoryFactory
    } from "../api/RepositoryFactory";
    const GetRepository = RepositoryFactory.get('data');


    import productItem from "../components/product/productItem";
    import productWrapper from "../components/product/productWrapper";
    import headerBg from "../components/app/headerBg";
    import sectionRigtLeft from "../components/home/sectionRigtLeft";
    export default {
        name: "PolyMethylamine",
        components: {
            headerBg,
            sectionRigtLeft,
            productWrapper,
            productItem,
            loading
        },
        async mounted() {
            await this.fetch()
        },
        data() {
            return {
                isLoading: false,
                data: {},
                title: {},
                page_settings: '',
                meta: [],
                og: [],
                locale: window.localStorage.getItem('locale'),
            }
        },

        watch: {
            '$route.params.slug': {
                immediate: true,
                handler() {
                    this.fetch()
                },
            },
        },

        methods: {
            fetch() {
                this.$store.dispatch('SET_LOADING_AXIOS', 2000)
                return new Promise((resolve, reject) => {
                    GetRepository.getMethylamine()
                        .then(resp => {
                            this.isLoading = false;
                            this.data = resp.data.data;
                            this.page_settings = resp.data.data.page_settings;
                            this.title = this.data.page.meta_title;
                            this.og = this.data.meta.og.meta;
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },
        },

        metaInfo() {
            return {
                title: this.title,
                meta: this.og
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .poly {
        @include sectionGlobal
    }
</style>
